import React from "react";
import Box from "@mui/material/Box";
import { Rating, Typography, Grid as MaterialGrid } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

export const StarRater = ({ setFormInput, formInput }) => {
  return (
    <div>
      <Typography style={{ textAlign: "initial" }}>Choose price:</Typography>
      <MaterialGrid
        item
        sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}
      >
        <Box className="rater" sx={{ marginBlockEnd: "12px" }}>
          <Rating
            name="half-rating"
            defaultValue={2}
            onChange={(e, value) => {
              setFormInput({ ...formInput, price: value.toString() });
            }}
            icon={<AttachMoneyIcon />}
            emptyIcon={<AttachMoneyIcon />}
            max={4}
          />
        </Box>
      </MaterialGrid>
    </div>
  );
};
