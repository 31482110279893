/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import "../App.css";
import { mobileAndTabletCheck } from "../utils";
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Link,
  Grid as MaterialGrid,
  Box,
  Button,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import Logo from "../logo.png";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import { NoResultCard } from "./NoResult";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const Results = ({ result, getData, setResetForm }) => {
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [mapLocation, setMapLocation] = useState("");
  const [center, setCenter] = useState("");
  const markerOffset = 0.004;

  useEffect(() => {
    if (result?.Location) {
      setAddress1(JSON.stringify(result?.Location.display_address[0]));
      setAddress2(JSON.stringify(result?.Location.display_address[1]));
      setMapLocation({
        address: `${address1}${address2}`,
      });
    }
    if (result?.Coordinates) {
      setCenter({
        lat: result?.Coordinates.Latitude - markerOffset,
        lng: result?.Coordinates.Longitude,
      });
    }
  }, [result?.Location, address1, address2, result?.Coordinates]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBr8nG48xdA1UoTZi8TCGlIJiPdi3fnyks",
  });

  const [map, setMap] = useState(null);

  const onLoad = useCallback(function callback(map) {
    map.setZoom(15);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const mapContainerStyle = {
    width: "-webkit-fill-available",
    height: "inherit",
  };

  const handleLinkClick = () => {
    const newMapUrl = `https://www.google.com/maps/search/${result.Name}${address1}${address2}`;
    window.open(newMapUrl);
  };
  let categoryArray = [];
  const getTitles = () => {
    if (result.Categories.length > 0) {
      result.Categories.map(c => categoryArray.push(c.Title));
      return categoryArray.join(", ");
    } else return "";
  };

  let currentView;
  if (result === undefined) {
    currentView = <NoResultCard setResetForm={setResetForm} />;
  } else {
    currentView = (
      <MaterialGrid
        container
        component="main"
        sx={{
          height: mobileAndTabletCheck() ? "-webkit-fill-available" : "100vh",
          width: "-webkit-fill-available",
        }}
      >
        <Box
          sx={{
            color: "white",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            height: "fit-content",
            paddingY: "8px",
            marginBlockStart: "8px",
            marginInline: "8px",
            backgroundColor: "rgba(27,39,52,0.9)",
            boxShadow: "0px 5px 5px 5px rgba(0, 0, 0, 0.1)",
            width: "-webkit-fill-available",
            borderRadius: "8px",
            zIndex: "2",
            position: "absolute",
            top: 0,
          }}
        >
          <img src={Logo} alt="Restaurant Roulette" width="50px" />
          <Typography variant="h5">Restaurant Roulette</Typography>
        </Box>
        <>
          {isLoaded && (
            <MaterialGrid
              item
              sx={{
                height: "-webkit-fill-available",
                display: "contents",
                zIndex: 1,
                position: "absolute",
                top: 0,
              }}
            >
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={10}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={{
                  disableDefaultUI: true,
                  fullscreenControl: false,
                  streetViewControl: false,
                }}
              >
                <MarkerF
                  position={{
                    lat: center.lat + markerOffset,
                    lng: center.lng,
                  }}
                  visible
                  zIndex={5}
                />
              </GoogleMap>
            </MaterialGrid>
          )}
          <Card
            className="card"
            sx={{
              width: "320px",
              backgroundColor: "rgba(255, 255, 255, 0.95)",
              boxShadow: "0px 5px 5px 5px rgba(0, 0, 0, 0.1)",
              position: "absolute",
              zIndex: 2,
              bottom: "10px",
              left: "50%",
              transform: "translateX(-50%)",
              margin: "auto",
              marginBlockEnd: "8px",
              "@media (min-width: 780px)": {
                left: "200px",
                marginBlockEnd: "12px ",
              },
            }}
          >
            <CardMedia sx={{ height: 120 }} image={result.image_url} />
            <CardContent align="initial">
              <MaterialGrid
                container
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  justifyContent: "flex-start",
                  gap: "12px",
                  alignItems: "baseline",
                }}
              >
                <Typography gutterBottom variant="h7" component="div">
                  {result.Name}
                </Typography>
                <MaterialGrid
                  item
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Typography variant="body1">{result.Rating}</Typography>
                  <StarIcon sx={{ color: "orange", fontSize: 16 }} />
                </MaterialGrid>
              </MaterialGrid>
              <Typography variant="body2" color="text.secondary">
                {getTitles()}
              </Typography>
              <Link
                target="_blank"
                onClick={handleLinkClick}
                variant="body2"
                component="button"
                sx={{ textAlign: "inherit" }}
              >
                {result.Location && result.Location.display_address.length >= 1
                  ? JSON.stringify(result.Location.display_address[0]).replace(
                      /"/g,
                      ""
                    )
                  : ""}
                <br />
                {result.Location && result.Location.display_address.length >= 2
                  ? JSON.stringify(result.Location.display_address[1]).replace(
                      /"/g,
                      ""
                    )
                  : ""}
              </Link>
              <Link
                href={`tel:${result.display_phone}`}
                variant="body2"
                sx={{ textAlign: "inherit" }}
              >
                <Typography variant="body2" color="text.secondary">
                  {result.display_phone}
                </Typography>
              </Link>
            </CardContent>
            <CardActions sx={{ justifyContent: "center" }}>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#E2E5DE", color: "black" }}
                onClick={() => setResetForm(true)}
              >
                <ArrowBackIcon />
              </Button>
              <Button variant="contained" onClick={() => getData()}>
                Spin
              </Button>
            </CardActions>
          </Card>
        </>
      </MaterialGrid>
    );
  }

  return <>{currentView}</>;
};
