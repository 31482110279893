import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const NoResultCard = ({ setResetForm }) => {
  return (
    <Card
      className="card"
      sx={{
        marginBlockEnd: "12px",
        marginBlockStart: "12px",
        height: 300,
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        width: 320,
        opacity: 0.95,
      }}
    >
      <CardContent align="initial">
        <Typography variant="h6">No Results</Typography>
        <Typography variant="body2">
          Try adjusting your search parameters.
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <Button
          variant="contained"
          sx={{ backgroundColor: "#E2E5DE", color: "black" }}
          onClick={() => setResetForm(true)}
        >
          <ArrowBackIcon />
        </Button>
      </CardActions>
    </Card>
  );
};
