import "./App.css";
import { StarRater } from "./components/StarRater";
import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  FormGroup,
  Grid as MaterialGrid,
  CssBaseline,
  Card,
} from "@mui/material";
import { Box } from "@mui/system";
import { Results } from "./components/Results";
import { v4 as uuidv4 } from "uuid";
import { useCookies } from "react-cookie";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import WebFont from "webfontloader";
import CircularProgress from "@mui/material/CircularProgress";
import Logo from "./logo.png";
import Background from "./background.png";
import { NoResultCard } from "./components/NoResult";
import "./fonts/Londrina/LondrinaSolid-Black.ttf";
import "./fonts/Londrina/LondrinaSolid-Light.ttf";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { mobileAndTabletCheck } from "./utils";

const SERVER_URL =
  process.env.NODE_ENV === "development" ? "http://localhost:8080" : "";

const generateIndexes = (total, every = 5) => {
  const indexes = [];
  const arr = [...Array(total).keys()];
  while (arr.length > 0) indexes.push(...shuffleArray(arr.splice(0, every)));
  return indexes;
};

const shuffleArray = arr => {
  const newArr = [];
  while (arr.length) {
    let i = Math.floor(Math.random() * arr.length),
      element = arr.splice(i, 1);
    newArr.push(element[0]);
  }
  return newArr;
};

export const App = () => {
  const [formInput, setFormInput] = useState({
    location: "",
    price: "2", //default to 2
    rating: "4", //default to 2.5
    radius: 40000, //default to max radius
    limit: 50,
  });

  const [data, setData] = useState(undefined);
  const [useCurrentLocation, setUseCurrentLocation] = useState(false);
  const [coordinate, setCoordinate] = useState({});
  const [cookies, setCookies] = useCookies();
  const [loading, isLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const [noResults, setNoResults] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [shuffledIndexes, setShuffledIndexes] = useState(
    generateIndexes(formInput.limit)
  );
  const [isNewVisitor, setIsNewVisitor] = useState(Boolean(cookies.visitorId));

  useEffect(() => {
    if (resetForm) {
      setFormInput({
        location: "",
        price: "2", //default to 2
        rating: "4", //default to 2.5
        radius: 40000, //default to max radius
        limit: 50,
      });
      setData(undefined);
      setNoResults(false);
    } else {
      setFormInput(formInput);
      setData(data);
      setResetForm(false);
    }
    setResetForm(false);

    if (!cookies.visitorId) {
      setCookies("visitorId", uuidv4());
    }
    WebFont.load({
      google: {
        families: ["LondrinaBold", "LondrinaLight"],
      },
    });

    window.addEventListener("load", function () {
      // Set a timeout...
      setTimeout(function () {
        // Hide the address bar!
        window.scrollTo(0, 1);
      }, 0);
    });
  }, [cookies, setCookies, setData, data, resetForm, formInput]);

  const handleToggle = () => {
    if (!navigator.geolocation) return;
    console.info("getCurrentPosition");
    navigator.geolocation.getCurrentPosition(
      pos => {
        console.info(pos.coords);
        const { latitude, longitude } = pos.coords;
        setCoordinate({ latitude, longitude });
      },
      err => {
        console.error(err);
        alert("Turn on GeoLocation");
        setUseCurrentLocation(false);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    );
  };

  async function getData() {
    const req = await fetch(`${SERVER_URL}/api/recommend/restaurant`, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        location: (formInput.location || "").trim(),
        price: formInput.price,
        term: "restaurants",
        open_now: true,
        radius: formInput.radius,
        latitude: useCurrentLocation ? coordinate.latitude : 0.0,
        longitude: useCurrentLocation ? coordinate.longitude : 0.0,
        new_visitor: isNewVisitor,
        sort_by: "rating",
        limit: formInput.limit,
        cursor: shuffledIndexes[index],
      }),
    });
    if (req.status === 500) {
      setNoResults(true);
      isLoading(false);
    } else {
      setNoResults(false);
      isLoading(true);
    }
    const result = await req.json();
    setData(result);
    isLoading(false);
    setIsNewVisitor(false);
    setIndex(result.new_data ? 0 : (index + 1) % result.total);
    if (result.new_data) {
      setShuffledIndexes(generateIndexes(result.total));
    }
  }

  const theme = createTheme({
    typography: {
      fontFamily: "Arial",
      h3: {
        fontFamily: "LondrinaBold",
      },
      h5: {
        fontFamily: "LondrinaBold",
      },
      caption: {
        fontFamily: "LondrinaBold",
      },
      h7: {
        fontFamily: "Arial",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MaterialGrid
        container
        component="main"
        sx={{
          height: mobileAndTabletCheck() ? "-webkit-fill-available" : "100vh",
          width: "-webkit-fill-available",
          backgroundImage: `url(${Background})`,
          backgroundSize: "cover",
          alignContent: "center",
        }}
      >
        {data === undefined && !loading && !noResults && (
          <>
            <MaterialGrid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                justifyContent: "center",
                display: "grid",
                alignContent: "baseline",
              }}
            >
              <Box
                sx={{
                  color: "white",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  display: "block",
                  height: "200px",
                  backgroundColor: "#1b2734",
                  width: "350px",
                  borderRadius: "8px 8px 0px 0px",
                }}
              >
                <MaterialGrid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    width: "350px",
                    alignItems: "center",
                    padding: "24px",
                  }}
                >
                  <img src={Logo} alt="Restaurant Roulette" width="96px" />
                  <Typography variant="h3">Restaurant Roulette</Typography>
                </MaterialGrid>
                <Typography
                  variant="caption"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "20px",
                  }}
                >
                  When you can't decide where to eat
                </Typography>
              </Box>
              <Card
                className="card"
                sx={{
                  marginBlockEnd: "12px",
                  marginBlockStart: "12px",
                  width: "-webkit-fill-available",
                  padding: "24px",
                  margin: "auto",
                  borderRadius: "0px 0px 8px 8px",
                  backgroundColor: "rgba(255, 255, 255, 0.95)",
                }}
              >
                {/* Form */}
                <MaterialGrid
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "grid",
                    gridGap: "12px",
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={<Switch checked={useCurrentLocation} />}
                      label="Use Current Location"
                      labelPlacement="start"
                      onChange={() => {
                        setUseCurrentLocation(!useCurrentLocation);
                        if (useCurrentLocation === false) {
                          handleToggle();
                        } else {
                          setFormInput({
                            ...formInput,
                            latitude: 0,
                            longitude: 0,
                          });
                        }
                      }}
                    />
                  </FormGroup>

                  <GooglePlacesAutocomplete
                    selectProps={{
                      isDisabled: useCurrentLocation,
                      setValue: useCurrentLocation ? "" : formInput.location,
                      placeholder: "City, address, or zipcode",
                      onChange: e => {
                        setFormInput({
                          ...formInput,
                          location: e.value.description,
                        });
                      },
                    }}
                    apiKey="AIzaSyBr8nG48xdA1UoTZi8TCGlIJiPdi3fnyks"
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: ["us"], //restrict to US
                      },
                    }}
                    onLoadFailed={error => console.log(error)}
                  />
                  <FormControl sx={{ width: "250px" }}>
                    <Select
                      value={formInput.radius}
                      onChange={e => {
                        const value = e.target.value;
                        setFormInput({ ...formInput, radius: value });
                      }}
                    >
                      <MenuItem value={40000}>
                        <em>Radius: 25 Miles / 40.23 KM </em>
                      </MenuItem>
                      <MenuItem value={805}>0.5 Miles / 0.80 KM</MenuItem>
                      <MenuItem value={1610}>1 Mile / 1.60 KM</MenuItem>
                      <MenuItem value={4828}>3 Miles / 4.82 KM</MenuItem>
                      <MenuItem value={8040}>5 Miles / 8.04 KM</MenuItem>
                      <MenuItem value={16090}>10 Miles / 16.09 KM</MenuItem>
                    </Select>
                  </FormControl>
                  <StarRater
                    setFormInput={setFormInput}
                    formInput={formInput}
                  />
                </MaterialGrid>
                <Box sx={{ display: "grid" }}>
                  <Button
                    onClick={() => {
                      getData();
                    }}
                    variant="contained"
                    sx={{ margin: "0 auto" }}
                    disabled={!useCurrentLocation && formInput.location === ""}
                  >
                    {data ? "Rerun" : "Submit"}
                  </Button>
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: "center",
                      marginBlockStart: "12px",
                      marginBlockEnd: "12px",
                    }}
                  >
                    Results are based off restaurants open now.
                  </Typography>
                  <Button
                    variant="text"
                    size="small"
                    color="info"
                    sx={{
                      textAlign: "center",
                      fontSize: "10px",
                    }}
                    onClick={() =>
                    (window.location =
                      "mailto:info.restaurantroulette@gmail.com")
                    }
                  >
                    Contact us
                  </Button>
                </Box>
              </Card>
            </MaterialGrid>
          </>
        )}

        {/** Results */}
        {loading && (
          <Box sx={{ alignItems: "center", margin: "0 auto", display: "flex" }}>
            <CircularProgress />
          </Box>
        )}
        {data?.total === 0 ||
          (data === undefined && !loading && noResults && (
            <NoResultCard setResetForm={setResetForm} />
          ))}
        {data !== undefined && !loading && !noResults && (
          <Results
            result={data.business}
            noResults={noResults}
            getData={getData}
            setResetForm={setResetForm}
          />
        )}
      </MaterialGrid>
    </ThemeProvider>
  );
};
